module.exports = {
  siteTitle: 'Box Pura Vida | Costa Rica', // <title>
  manifestName: 'boxpuravida',
  manifestShortName: 'puravida', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/boxpuravida/`, // This path is subpath of your hosting https://domain/portfolio
  // social
  heading: 'Box Pura Vida',
  subHeading:
    "Por que las mejores sorpresas vienen en una caja.",
  socialLinks: [
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://instagram.com/box_puravida',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:contacto@boxpuravida.com',
    },
  ],
};
